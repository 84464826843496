window._ = require('lodash');
window.$ = require('jquery')
const swiper = require('swiper');
window._swiper = swiper.default;
window._swiperPagination = swiper.Pagination;
window._swiperMousewheel = swiper.Mousewheel;
window._swiperNavigation = swiper.Navigation;
window._swiperKeyboard = swiper.Keyboard;
window._swiperAutoPlay = swiper.Autoplay;

// try {
//     //window.Popper = require('popper.js').default;
//     // require('bootstrap');

//     // window.ps = require('perfect-scrollbar');
//     // require('./libs/perfect-scrollbar/dist/js/perfect-scrollbar.jquery.js')(window.$)
// } catch (e) {

// }
